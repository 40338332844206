import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Container,
  Grid,
  Link,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import styled from "styled-components";
import emailjs, { EmailJSResponseStatus } from "emailjs-com";

interface ContactFormValues {
  interest: string;
  email: string;
  message: string;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error";
}
const ContactInfo = styled.div`
  color: white;
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.1rem;
  width: 100%;
  padding: 0 0.5rem;

  .label {
    font-weight: 600;
    margin-right: 0.3rem;
  }

  .separator {
    margin: 0 0.8rem;
    color: white;
  }

  .value {
    color: #e0e0e0;
    font-weight: 300;
  }

  .email-link {
    color: #e0e0e0;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

const MainConnected = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
`;

const StyledContainer = styled(Container)`
  border-radius: 0.5em;
  width: 80% !important;
  padding: 4em;

  @media (max-width: 768px) {
    width: 90% !important;
  }
  @media (max-width: 480px) {
    width: 95% !important;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    background: #f5f5f5;
    border-radius: 0.4em;
    border: none;

    fieldset {
      border-color: transparent !important;
    }

    input {
      color: black !important;
    }

    label {
      color: black !important;
    }

    &:hover fieldset {
      border-color: transparent;
    }
    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #3c5099;
    color: #fff;
    margin-top: 1em;
    font-weight: bold;
    letter-spacing: 0.05rem;
    text-transform: none;
    padding: 0.75em 1.5em;
    border: none;
    border-radius: 0.4em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &:hover {
      background-color: #3c5099;
      color: #e0e0e0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      background-color: #2c2f33;
      color: #4f5256;
      cursor: not-allowed;
      box-shadow: none;
    }

    .MuiButton-label > a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const StyledTypography = styled(Typography)`
  color: #fff;
  font-size: 1.6em;
  line-height: 1.5;
  margin: 0.5em 0;
`;

const StyledSelectField = styled(TextField)`
  fieldset {
    border-color: transparent !important;
  }

  input {
    color: black !important;
  }

  label {
    color: black !important;
  }

  &:hover fieldset {
    border-color: transparent;
  }
  &.Mui-focused fieldset {
    border-color: transparent;
  }

  label {
    color: #8e8e8e !important;
  }

  .MuiInputBase-root {
    color: black;
    background-color: #f5f5f5;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #555;
  }

  .MuiInputLabel-root {
    color: ${(props) => (props.value ? "transparent" : "black")} !important;
  }

  .MuiSelect-icon {
    color: #fff;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bbb;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }

  &.Mui-focused .MuiInputLabel-root {
    color: #fff;
  }

  background: #333;
  border-radius: 4px;

  .MuiMenu-paper {
    background-color: #333;
  }

  .MuiMenuItem-root {
    color: #fff;

    &:hover {
      background-color: #444;
    }

    &.Mui-selected {
      background-color: #555;
    }
  }
`;

const Contact = () => {
  const [formValues, setFormValues] = useState<ContactFormValues>({
    interest: "",
    email: "",
    message: "",
  });

  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "success",
  });

  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValidEmail(formValues.email)) {
      setSnackbar({
        open: true,
        message: "Please enter a valid email address.",
        severity: "error",
      });
      return;
    }
    try {
      const formData = {
        ...formValues,
        interest: String(formValues.interest),
        email: String(formValues.email),
        message: String(formValues.message),
      };

      const response: EmailJSResponseStatus = await emailjs.send(
        "service_u6koi2i",
        "template_gj23w3f",
        formData,
        "RA9CGbF71JiWqW76a"
      );
      console.log("Email sent successfully:", response);
      setSnackbar({
        open: true,
        message: "Email sent successfully!",
        severity: "success",
      });
      setFormValues({ interest: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbar({
        open: true,
        message: "An error occurred while sending the email.",
        severity: "error",
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <StyledContainer maxWidth="sm" sx={{ marginTop: "-6rem" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <StyledSelectField
              select
              sx={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
              label="I want"
              fullWidth
              name="interest"
              value={formValues.interest || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: false }}
            >
              <MenuItem value="interview">An interview</MenuItem>
              <MenuItem value="music_concert">A music concert</MenuItem>
              <MenuItem value="composed_soundtrack">
                An original composed soundtrack
              </MenuItem>
              <MenuItem value="music_production">
                Music production / an arrangement / mixing
              </MenuItem>
              <MenuItem value="cooperate_musically">
                To cooperate musically
              </MenuItem>
              <MenuItem value="other">other</MenuItem>
            </StyledSelectField>

            <StyledTextField
              fullWidth
              focused
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />

            <StyledTextField
              fullWidth
              name="message"
              placeholder="Write your message here"
              multiline
              rows={4}
              value={formValues.message}
              onChange={handleChange}
              sx={{
                background: "#333",
                borderColor: "white",
                color: "white !important",
                marginTop: "1em",
              }}
            />
            <StyledButton type="submit" fullWidth>
              Send
            </StyledButton>
            <ContactInfo className="contact-info-responsive">
              <span className="label">Email:</span>
              <a
                href="mailto:yoavasif@gmail.com"
                className="email-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                yoavasif@gmail.com
              </a>
              <span className="separator">|</span>
              <span className="label">Phone:</span>
              <a href="tel:0523779937" className="phone-link value">
                0523779937
              </a>
            </ContactInfo>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default Contact;
